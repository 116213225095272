var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('master-page',{attrs:{"icone":"mdi-email","titulo":"Carta Rastreabilidade","subTitulo":"Lista de armazenamentos para uso no sistema."}},[_c('v-row',{staticClass:"toolbar",attrs:{"align":"center"}},[_c('v-text-field',{staticClass:"mx-2",attrs:{"label":"Pesquisa rápida","clearable":"","single-linehide-details":""},on:{"change":function($event){return _vm.Atualizar()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.ExportarExcel('tabela')}}},on),[_c('v-icon',[_vm._v("mdi-file-excel-outline")])],1)]}}])},[_c('span',[_vm._v("Exportar (Excel)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.Atualizar()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.app.role == 'Admin' || _vm.app.role == 'Residuo'|| _vm.app.role == 'ResiduoArquivista')?_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.AbrirDialogCadastro()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Novo")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tabela","headers":_vm.headers,"items":_vm.lista,"item-class":_vm.itemOSClass,"options":_vm.options,"server-items-length":_vm.totalLista,"loading":_vm.loading,"footer-props":{ showFirstLastPage: true}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [((_vm.app.role == 'Admin' || _vm.app.role == 'Residuo'|| _vm.app.role == 'ResiduoArquivista'))?_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.AbrirDialogCadastro(item)}}},on),[_vm._v("mdi-pencil")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [((item.usuarioId == _vm.app.usuarioId || item.responsavelId == _vm.app.usuarioId) && item.situacaoId == 1)?_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.Cancelar(item)}}},on),[_vm._v("mdi-cancel")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Cancelar")])]),(item.situacaoId != 1 || item.responsavelId == _vm.app.usuarioId)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.AbrirDialogDetalhar(item)}}},on),[_vm._v("mdi-file-document")])]}}],null,true)},[_c('span',[_vm._v("Detalhar")])]):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.app.role == 'Admin' || _vm.app.role == 'Residuo'|| _vm.app.role == 'ResiduoArquivista')?_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.Excluir(item)}}},on),[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}},{key:"item.dataHoraCriacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataHoraCriacao.toDateDDMMYYYY())+" ")]}},{key:"item.aprovado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.aprovado.toSimNao())+" ")]}}],null,true)}),_c('cadastro-cartarastreabilidade',{attrs:{"item":_vm.item},on:{"fechou":function($event){_vm.dialogCadastro = false},"salvou":function($event){return _vm.Atualizar()}},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}}),_c('v-dialog',{attrs:{"persistent":"","persistent":"","max-width":"600px"},model:{value:(_vm.detalharDialog),callback:function ($$v) {_vm.detalharDialog=$$v},expression:"detalharDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Escolha o layout de Carta de Rastreabilidade")])]),_c('v-card-text',[_c('v-container',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.layoutDetalhar),callback:function ($$v) {_vm.layoutDetalhar=$$v},expression:"layoutDetalhar"}},[_c('v-radio',{attrs:{"label":"Layout 1","value":1}}),_c('v-radio',{attrs:{"label":"Layout 2","value":2}}),_c('v-radio',{attrs:{"label":"Layout 3","value":3}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.detalharDialog = false}}},[_vm._v("Fechar")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.Detalhar()}}},[_vm._v("Visualizar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }